
const config = {
  type: "service_account",
  apiKey: "AIzaSyBBxxWkMuVSAzfMfX_Th0cFTLD3HJc7JNw",
  authDomain: "minipass-31e6e.firebaseapp.com",
  databaseURL: "https://minipass-31e6e.firebaseio.com",
  projectId: "minipass-31e6e",
  storageBucket: "minipass-31e6e.appspot.com",
  messagingSenderId: "757635564973",
  appId: "1:757635564973:web:d39f8f033c9f9be1ed8d24",
  private_key_id: "e11485db227f1ec2bbe76e43096ac8bfb43b7c8d",
  private_key:
    "-----BEGIN PRIVATE KEY-----\nMIIEvgIBADANBgkqhkiG9w0BAQEFAASCBKgwggSkAgEAAoIBAQDCYIAX0HwpipD+\ngKUZ1yioZrd+Kj3qssqbx1L7K3TFHwNKC9jW5/bO3uUQ2uZG/c8qnblbarTVEO5Q\n5w1NsqvuvoxAaIHtEiVDdT011D8DD8imHnGLxL0S9FhPV32WBXshq0HVQRQBkWm3\nh0+OeejfysNIO+2PRg0EsjfSi0zoAazIG29T4UyuuTKnfWlE4Ze3hsFxtJr813EV\ntUpK/0VLJdZx0Ea5vbpeFTxNrFeBVGgURbONFh1ZjQkLE481M6os755T5trYEdoF\nYhjiw6co2n8hlHtFD0eyxeczWfsPSILY5emohinMxj5H2AitVNm2r18yfXmgKfi7\n5OjfB7QfAgMBAAECggEAK0eybdsJsnQ8mbaKTIkob+rDI7ADLl2WGH6W3v9LllKz\n+EpQA3YN8f0WcxzSlm8QMFSDP7QwRJrq/Y+c2jaU6ul5sCO0/lUCgBU8Rl6Wkzdx\n/W8c6DqrwOswOKZp+7wHsTUBSRse+cRnjipuT/KcJCxxNAXH/RVjc669GD/VJXlK\np+oyteygJ6n84u/vuCa1AqMeFksW+B5/lTxJll5X+K93IeKhdtf+f+fMNLs/ZYKQ\nPUSpN5Cre9MTBk2arIW9mIDbvRm1nYpp2j3v6dmTG0q5uckfEmPUhpXOnWK+LAHd\nnqjHbe4nDRPzpkwG/W6mQUoHvBeLruohDam9/8+sfQKBgQDhTl5ZdVSy9VKltmCj\nkZ4EJCGSiEPLrOkyCVSorZsF5Iok+Fbz6YGY0UfrivXKfEQ2NipqYprZm05X3NXr\nWT/jfFH/Q2zDGpMUUCC2E4373Q2kVdqVFRYJN+q63X420jO1bukSeYK9TWIUtgi5\ntAdP4pQvXuoUogmevKPXg25F+wKBgQDc23dFE3roHrXc3ImJqCtNcwaTyYSBnAZt\nFia93ZDoJh7J2D8rlA/u/folMz7BJSIO6Cbfli56F541gva0TcQA9t5VjNDQZWs0\nZP1rsKZFc3ftbTJuwLCOF7sb/81xBIwlnv8TZa99CXBpkCGdziBg9myxP+1BSLk9\n2kJPfr6FLQKBgQDZOwpVDn9/nHD3PYSnsLq+G2gTNDbJpgmhKMXrbJdPcy6OvrJ3\nseg2Q3IodG7PsEjETC1RLYlHzioXJOFMbVzgJPmaOJQg+PpeV3NymiAHevawP+bk\nXtRERxmW2f/bM4iPokQuf+HCS14Jwglfo2h5aWie09C13BWSayc9n4cvCQKBgQCJ\nbxCDLaIX+7RT4fC8dqerpF59bLNGLB9blqM5Fz1rGP2jwSteJN7da7JTn9X3PvI4\nsIIwNDZrx3Kt/fcjMBk+OGEWf3AZnmLd0dJZRy3QnhAM8RynCsVTRrUqsT3YjGnh\np1U3btmLR2pqZkRk/LXtYKs2iX9MVsLfGIiXC1nnaQKBgHi7ZoEvwdveUSC2gs/e\nUmH3qRRdyRK+PWcDY0/6nDynlrTEwmutXw38x/Lo14XlcIx6A8FDyJD+WJlelzqx\n2DmxBSCStL7zOsh3wHAed4EuhiREwBARFFI/no8ZnzmvXieh3MMrawN3Ue3S0ZVx\nh7HrMTYd/lZamkFWK+0WW+R7\n-----END PRIVATE KEY-----\n",
  client_email:
    "firebase-adminsdk-dv0u1@minipass-31e6e.iam.gserviceaccount.com",
  client_id: "101747816530910257782",
  auth_uri: "https://accounts.google.com/o/oauth2/auth",
  token_uri: "https://oauth2.googleapis.com/token",
  auth_provider_x509_cert_url: "https://www.googleapis.com/oauth2/v1/certs",
  client_x509_cert_url:
  "https://www.googleapis.com/robot/v1/metadata/x509/firebase-adminsdk-dv0u1%40minipass-31e6e.iam.gserviceaccount.com",
  measurementId: "G-Q93ZKXD4J2"
};


export default config;
