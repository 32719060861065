import React from "react";
import Button from "@material-ui/core/Button";
import logo from "../images/logo.svg";
import { useAuth } from "../contexts/AuthContext";
import { Link } from "react-router-dom";
import * as ROUTES from "../utils/routes";

const Header: React.FC = () => {
  const { currentUser, logout } = useAuth();

  return (
    <header className="header">
      <div className="header__container">
        <Link to={ROUTES.HOME}>
          <div className="logo">
            <img className="logo__icon" src={logo} alt="logo" />
            <span className="logo__name">minipass</span>
          </div>
        </Link>
        <div className="header__links">
          {currentUser && (
            <>
              <nav className="nav">
                <ul>
                  <li>
                    <Link to={ROUTES.ACTIVITIES}>Activities</Link>
                  </li>

                  {/* <li>
                <a href="./about">About us</a>
              </li>
              <li>
                <a href="./contact">Contact</a>
              </li> */}
                </ul>
              </nav>
              <div style={{ display: "flex" }} id="whenSignedOut">
                <Button
                  id="signInBtn"
                  variant="outlined"
                  onClick={() => logout()}
                >
                  Sign out
                </Button>
              </div>
            </>
          )}

          {!currentUser && (
            <>
              <nav className="nav">
                <ul>
                  <li>
                    <Link to={ROUTES.ABOUT}>About us</Link>
                  </li>
                  <li>
                    <Link to={ROUTES.CONTACT}>Contact us</Link>
                  </li>
                </ul>
              </nav>
              <div style={{ display: "flex" }} id="whenSignedOut">
                <Button
                  id="signInBtn"
                  color="primary"
                  variant="contained"
                  disableElevation
                  href={ROUTES.SIGN_IN}
                >
                  Sign in
                </Button>
              </div>
            </>
          )}
          {/* <div id="whenSignedIn">
              <button
                style={{ marginRight: "10px" }}
                type="button"
                className="btn btn-link"
                id="signOutBtn"
              >
                Sign out
              </button>
            </div> */}
        </div>
      </div>
    </header>
  );
};

export default Header;
