import "firebase/auth";
import "firebase/storage";
import firebase from "firebase";

export class Storage {
  private storage: firebase.storage.Storage;
  private auth: firebase.auth.Auth;

  constructor(storage: firebase.storage.Storage, auth: firebase.auth.Auth) {
    this.storage = storage;
    this.auth = auth;
  }

  async uploadImageGetRef(
    activityId: string,
    file: File
  ): Promise<firebase.storage.Reference> {
    const fileExtension = this.getFileExtension(file);
    const imageRef = this.getImageStorageRefWithExtension(
      activityId,
      fileExtension
    );

    await this.uploadFileWithFileRef(file, imageRef);
    return imageRef;
  }

  private getImageStorageRefWithExtension(
    activityId: string,
    fileExtension: string
  ): firebase.storage.Reference {
    return this.getImageRef(activityId, fileExtension);
  }

  async getDownURLFromReferencePath(fileRefPath: string): Promise<string> {
    const fileRef = this.storage.ref(fileRefPath);
    return await fileRef.getDownloadURL();
  }

  private async uploadFileWithFileRef(
    file: File,
    fileRef: firebase.storage.Reference
  ): Promise<void> {
    await fileRef.put(file);
    console.log("File uploaded to path:", fileRef);
  }

  private getImageRef(
    activityId: string,
    fileExtension: string,
    suffix = ""
  ): firebase.storage.Reference {
    if (!activityId) {
      throw Error("No activity id, cannot get ref");
    }

    const targetFilePath = `images/activity/${activityId}/image${suffix}.${fileExtension}`;
    const fileRef = this.storage.ref(targetFilePath);
    return fileRef;
  }

  private getFileExtension(file: File): string {
    const fileNameParts = file.name.split(".");
    const fileExtension = fileNameParts[fileNameParts.length - 1];
    return fileExtension;
  }
}
