import React, { useEffect, useState } from "react";
import getAddressCoordinates from "../../utils/googleMaps";
import Activity from "../../models/activities";
import GoogleMap from "./GoogleMap";
import TextField from "@material-ui/core/TextField";
import Button from "@material-ui/core/Button";
import PinDropIcon from "@material-ui/icons/PinDrop";
import IconButton from "@material-ui/core/IconButton";
import GpsFixedIcon from "@material-ui/icons/GpsFixed";
import CheckIcon from "@material-ui/icons/Check";
import ArrowLeftIcon from "@material-ui/icons/ArrowLeft";
import CancelOutlinedIcon from "@material-ui/icons/CancelOutlined";
import CheckCircleIcon from "@material-ui/icons/CheckCircle";
import CheckCircleIconOutlined from "@material-ui/icons/CheckCircleOutlined";
import EmojiObjectsOutlinedIcon from "@material-ui/icons/EmojiObjectsOutlined";
import FormControl from "@material-ui/core/FormControl";
import OutlinedInput from "@material-ui/core/OutlinedInput";
import Spinner from "./LoadingAnimation";
import Select from "@material-ui/core/Select";
import MenuItem from "@material-ui/core/MenuItem";
import { Topics, FoodSubTopics, CoursesSubTopics } from "../../models/topics";
import InputAdornment from "@material-ui/core/InputAdornment";
import { Cities } from "../../models/cities";
import InputLabel from "@material-ui/core/InputLabel";
import { PictureAsPdf } from "@material-ui/icons";
import { PictureWithUpload } from "./ImageUpload";
import {
  uploadActivityImageAndUpdateActivityDataReturnURL,
  storage,
  database,
} from "../../utils/firebase";
import { firestoreAutoId } from "../../utils/firestoreAutoId";
import * as ROUTES from "../../utils/routes";
import Checkbox from "@material-ui/core/Checkbox";
import moment from "moment";

import {
  BrowserRouter as Router,
  Route,
  Switch,
  Redirect,
  useHistory,
  Link,
} from "react-router-dom";
import { act } from "@testing-library/react";

interface NewActivityProps {
  onAddOrEditActivity: (activityId: string, activity: Activity) => void;
  selectedActivityId: string | null;
  selectedActivity: Activity | null;
}

const getValidLatLongValue = (input: any) => {
  if (!input) return 0.0;
  if (typeof input === "string") {
    return parseFloat(input);
  }
  return input;
};

const ActivityForm: React.FC<NewActivityProps> = ({
  onAddOrEditActivity,
  selectedActivity,
  selectedActivityId,
}) => {
  let history = useHistory();
  const activitySubmitHandler = (event: React.FormEvent) => {
    event.preventDefault();
    let activity = {
      ...state,
      tags: state.tags.split(",").map((tag: string) => tag.trim()) || [],
      related_activities:
        state.related_activities
          .split(",")
          .map((activity: string) => activity.trim()) || [],
    };
    onAddOrEditActivity(activityId, activity as Activity);
  };
  const [formComplete, setFormComplete] = useState<boolean>(false);
  const [isLoading, setLoading] = React.useState<boolean>(false);

  const [activityId, setActivityId] = useState<string>(
    selectedActivityId ? selectedActivityId : firestoreAutoId()
  );
  const [state, setState] = React.useState<any>({
    published: false,
    name: "",
    address: "",
    company: "",
    city: "stockholm",
    lat: 0.0,
    long: 0.0,
    description: "",
    topic: "",
    ["sub-topic"]: "",
    tags: "",
    related_activities: "",
    url: "",
    image_url: "",
    discount: 0,
  });

  useEffect(() => {
    formIsReadyToSubmit();
  }, [state]);

  useEffect(() => {
    setState({
      published:
        typeof selectedActivity?.published == "undefined" ||
        selectedActivity?.published
          ? true
          : false,
      name: selectedActivity?.name ? selectedActivity.name : "",
      address: selectedActivity?.address ? selectedActivity.address : "",
      company: selectedActivity?.company ? selectedActivity.company : "",
      city: selectedActivity?.city ? selectedActivity.city : "stockholm",
      lat: getValidLatLongValue(selectedActivity?.lat),
      long: getValidLatLongValue(selectedActivity?.long),
      description: selectedActivity?.description
        ? selectedActivity.description
        : "",
      topic: selectedActivity?.topic ? selectedActivity.topic : "",
      "sub-topic": selectedActivity?.["sub-topic"]
        ? selectedActivity["sub-topic"]
        : "",
      tags: selectedActivity?.tags ? selectedActivity.tags.join(", ") : "",
      related_activities: selectedActivity?.related_activities
        ? selectedActivity.related_activities.join(", ")
        : "",
      url: selectedActivity?.url ? selectedActivity.url : "",
      image_url: selectedActivity?.image_url ? selectedActivity.image_url : "",
      discount: selectedActivity?.discount ? selectedActivity.discount : 0,
    });
  }, [selectedActivity]);

  const formIsReadyToSubmit = () => {
    if (state.name !== "" && state.company !== "" && validCoordinates()) {
      setFormComplete(true);
    } else {
      setFormComplete(false);
    }
  };

  const validCoordinates = () => {
    return state.lat !== 0 && state.long !== 0;
  };

  const activityAddressHandler = (event: React.FormEvent) => {
    event.preventDefault();
    if (!state.address || state.address === "") return;
    getAddressCoordinates(state.address! + ", " + state.city).then(
      (coordinates) => {
        setState({ ...state, lat: coordinates.lat, long: coordinates.lng });
      }
    );
  };

  const handleChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    setState({
      ...state,
      [event.currentTarget.name]: event.currentTarget.value as string,
    });
  };
  const handlePublished = (event: React.ChangeEvent<HTMLInputElement>) => {
    setState({
      ...state,
      published: event.currentTarget.checked as boolean,
    });
  };
  const handleTopicChange = (event: React.ChangeEvent<{ value: unknown }>) => {
    setState({
      ...state,
      topic: event.target.value as string,
    });
  };
  const handleSubTopicChange = (
    event: React.ChangeEvent<{ value: unknown }>
  ) => {
    setState({
      ...state,
      "sub-topic": event.target.value as string,
    });
  };
  const handleCityChange = (event: React.ChangeEvent<{ value: unknown }>) => {
    setState({
      ...state,
      city: event.target.value as string,
    });
  };

  const createActivityImageUrl = (imageUrl: string) => {
    setState({ ...state, image_url: imageUrl });
  };
  return (
    <>
      {isLoading && <Spinner />}

      <section>
        <div className="form__container">
          <h1>{selectedActivity ? "Edit activity" : "New activity"}</h1>
          <form onSubmit={activitySubmitHandler}>
            <div className="form__field">
              <TextField
                required
                name="name"
                label="Activity name"
                variant="outlined"
                value={state.name}
                onChange={handleChange}
                fullWidth
              />
            </div>
            <div className="form__field">
              <TextField
                required
                name="company"
                label="Company"
                variant="outlined"
                value={state.company}
                onChange={handleChange}
                fullWidth
              />
            </div>
            <div className="form__field">
              <TextField
                required
                type="url"
                name="url"
                label="Website"
                variant="outlined"
                value={state.url}
                onChange={handleChange}
                fullWidth
              />
            </div>
            <div className="form__field">
              <FormControl variant="outlined" fullWidth>
                <InputLabel id="demo-simple-select-outlined-label">
                  Topic
                </InputLabel>

                <Select
                  required
                  labelId="demo-simple-select-outlined-label"
                  id="demo-simple-select-outlined"
                  name="topic"
                  value={state.topic}
                  onChange={handleTopicChange}
                  label="Topic"
                >
                  <MenuItem value={""}>
                    <em>Category</em>
                  </MenuItem>
                  {Object.keys(Topics).map((topic: string) => {
                    return (
                      <MenuItem key={topic} value={topic}>
                        {Topics[topic as string]}
                      </MenuItem>
                    );
                  })}
                </Select>
              </FormControl>
            </div>

            <div className="form__field">
              <TextField
                name="tags"
                label="Tags"
                variant="outlined"
                value={state.tags}
                onChange={handleChange}
                fullWidth
              />
              <p>
                Add tags separated by commas.
                <br />
                Eg: <em>cafe, restaurant, weekend</em>
              </p>
              <p>
                To show activities as suggestions on the homepage you can use:
                <ul>
                  <li>weekend</li>
                  <li>indoors</li>
                  <li>outdoors</li>
                </ul>
              </p>
              <p>
                You can also use tags for the sub-categories:
                <ul>
                  <li>Courses</li>
                  <ul>
                    <li>baby music</li>
                    <li>baby swim</li>
                    <li>baby CPR</li>
                    <li>family photography</li>
                  </ul>
                  <li>Food</li>
                  <ul>
                    <li>brunch</li>
                    <li>cafe</li>
                    <li>restaurant</li>
                  </ul>
                </ul>
              </p>
              <br />
            </div>
            <div className="form__field">
              <TextField
                name="related_activities"
                label="Related activities"
                variant="outlined"
                value={state.related_activities}
                onChange={handleChange}
                fullWidth
              />
              <p>
                Add other activities that we recommend if users click this
                actity, they will be shown at the bottom of the page.
                <br />
                Eg.: <em>animals-1, courses-23, 8qA72BEgQ1gGfb9Jt0UE</em>
              </p>
              <br />
            </div>

            <div className="form__field">
              <h3>Description</h3>
              <p>
                Give a short description of the activity, and explain why it is
                good for parents with toddlers.
              </p>
              <ol className="form__description__list">
                <li>What is the purpose of the activity?</li>
                <li>Why do you recommend it for parents with toddlers?</li>
                <li>What amenites are available?</li>
              </ol>
              <TextField
                name="description"
                // label="Description"
                variant="outlined"
                value={state.description}
                multiline
                rows={4}
                onChange={handleChange}
                fullWidth
              />
            </div>

            <div className="form__field">
              <div className="form__info-box">
                <div className="form__info-box__content form__info-box__content--column">
                  <div className="form__description__header">
                    <EmojiObjectsOutlinedIcon
                      fontSize="large"
                      className="form__icon"
                    />
                    <h4>Here's an example</h4>
                  </div>

                  <div>
                    <span className="form__strong">"</span>
                    <span>
                      This is an activity for all parents interested in arts.
                      Strollers are welcome and there is a changing room with
                      all amenities.
                    </span>
                    <span className="form__strong">"</span>
                  </div>
                </div>
              </div>
            </div>
            <div className="form__field">
              <h3>Image</h3>
              <PictureWithUpload
                updateImageUrl={createActivityImageUrl}
                activityId={activityId}
                activity={state}
              />
            </div>
            <div className="form__field">
              <TextField
                required
                type="url"
                name="imageUrl"
                label="Image url"
                variant="outlined"
                value={state.image_url}
                onChange={handleChange}
                fullWidth
              />
            </div>

            <div className="form__field">
              <h3>Location</h3>
              <FormControl variant="outlined" fullWidth>
                <InputLabel id="demo-simple-select-outlined-label">
                  City
                </InputLabel>

                <Select
                  required
                  // labelId="demo-simple-select-outlined-label"
                  id="demo-simple-select-outlined"
                  name="city"
                  value={state.city}
                  onChange={handleCityChange}
                  label="City"
                >
                  <MenuItem value={""}>
                    <em>City</em>
                  </MenuItem>
                  {Object.keys(Cities).map((city: string) => {
                    return (
                      <MenuItem key={city} value={city}>
                        {Cities[city as string]}
                      </MenuItem>
                    );
                  })}
                </Select>
              </FormControl>
            </div>
            <div className="form__field">
              <TextField
                required
                type="text"
                name="address"
                label="Address"
                variant="outlined"
                value={state.address}
                onChange={handleChange}
                fullWidth
              />
            </div>

            <div className="form__field">
              <div className="form__info-box">
                <div
                  onClick={activityAddressHandler}
                  className={`form__info-box__content form__info-box__address ${
                    validCoordinates() && "form__info-box__address--success"
                  }`}
                >
                  <div style={{ display: "flex", alignItems: "center" }}>
                    <IconButton
                      aria-label="get address coordinates"
                      onClick={activityAddressHandler}
                    >
                      <GpsFixedIcon color="primary" />
                    </IconButton>
                    {validCoordinates() ? (
                      <>
                        <span>
                          {state.lat?.toFixed(6)}, {state.long?.toFixed(6)}
                        </span>
                      </>
                    ) : (
                      <span>click to get coordinates</span>
                    )}
                  </div>

                  {validCoordinates() && (
                    <CheckIcon className="form__check-icon" />
                  )}
                </div>
              </div>
            </div>
            {/* <div>
            <label htmlFor="activity-lat">Lat</label>
            <input type="text" id="activity-lat" value={coordinates.lat} />
          </div>
          <div>
            <label htmlFor="activity-lng">Long</label>
            <input type="text" id="activity-lng" value={coordinates.lng} />
          </div> */}
            <div className="form__map">
              <GoogleMap coordinates={{ lat: state.lat!, lng: state.long! }} />
            </div>

            {/* <div className="form__field">
              <h3>Discount for card holders</h3>
              <p>
                What discount will you give to MiniPass card holders? This will
                entitle the card holders to the discounted price when they
                present their card.
              </p>
              <OutlinedInput
                required
                type="number"
                name="discount"
                // label="Discount"
                // variant="outlined"
                value={state.discount}
                onChange={handleChange}
                // fullWidth
                placeholder="20"
                endAdornment={<InputAdornment position="end">%</InputAdornment>}
              />
            </div> */}
            <br />

            <div className="form__field">
              <div className="form__description__header">
                <Checkbox
                  checked={state.published}
                  onChange={handlePublished}
                  name="published"
                  color="primary"
                />
                <p>
                  {state.published
                    ? "Publish (visible on the app)"
                    : "Draft (not visible on the app)"}
                </p>
              </div>
            </div>
            <div className="form__field form__buttons">
              <Button
                variant="contained"
                type="submit"
                disableElevation
                color="primary"
                size="large"
                disabled={!formComplete}
              >
                Save
              </Button>
              <span style={{ margin: "0 5px" }}>&nbsp;</span>
              <Button
                variant="outlined"
                disableElevation
                size="large"
                onClick={() => {
                  history.push(ROUTES.ACTIVITIES);
                }}
              >
                Cancel
              </Button>
            </div>
          </form>
        </div>
      </section>
    </>
  );
};

export default ActivityForm;
