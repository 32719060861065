import React, { useState } from "react";
import { useAuth } from "../contexts/AuthContext";
import TextField from "@material-ui/core/TextField";
import Button from "@material-ui/core/Button";
import LoadingAnimation from "./admin/LoadingAnimation";

const Login: React.FC = () => {
  const { login } = useAuth();
  const [error, setError] = useState("");
  const [loading, setLoading] = useState(false);
  const [state, setState] = useState({ email: "", password: "" });

  async function handleSubmit(event: React.FormEvent) {
    event.preventDefault();
    setLoading(true);
    try {
      setError("");
      setLoading(true);
      await login(state.email, state.password);
    } catch {
      setError("Failed to log in");
    }
    setLoading(false);
  }

  const handleChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    setState({
      ...state,
      [event.currentTarget.name]: event.currentTarget.value as string,
    });
  };

  return (
    <>
      {loading && LoadingAnimation}
      <div className="container">
        <div className="login-form">
          <form onSubmit={handleSubmit}>
            <div className="form__field">
              <TextField
                required
                name="email"
                label="Email"
                variant="outlined"
                value={state.email}
                type="email"
                onChange={handleChange}
                fullWidth
              />
            </div>
            <div className="form__field">
              <TextField
                required
                name="password"
                label="Password"
                variant="outlined"
                type="password"
                value={state.password}
                onChange={handleChange}
                fullWidth
              />
            </div>
            {error !== "" && <div className="alert--error">{error}</div>}
            <Button
              variant="contained"
              type="submit"
              disableElevation
              color="primary"
              size="large"
            >
              Log in
            </Button>
          </form>
        </div>
      </div>
    </>
  );
};

export default Login;
