import { topic } from "firebase-functions/lib/providers/pubsub";
import firebase from "firebase/app";
import Activity from "../../models/activities";
// const { serverTimestamp } = firebase.firestore.FieldValue;
const COLLECTION_ID_ACTIVITIES = "activities";

export class Database {
  private db: firebase.firestore.Firestore;
  private auth: firebase.auth.Auth;

  constructor(db: firebase.firestore.Firestore, auth: firebase.auth.Auth) {
    this.db = db;
    this.auth = auth;
  }

  async addNewActivity(activityId: string, activity: Activity) {
    console.log("saving activity: " + activityId);
    await this.db
      .collection(COLLECTION_ID_ACTIVITIES)
      .doc(activityId)
      .set(
        {
          ...activity,
          date_created: firebase.firestore.FieldValue.serverTimestamp(),
          date_last_edited: firebase.firestore.FieldValue.serverTimestamp(),
          added_by: "admin",
        },
        { merge: true }
      );
  }

  async updateActivity(
    activityId: string,
    previousData: Activity
  ): Promise<void> {
    await this.db
      .collection(COLLECTION_ID_ACTIVITIES)
      .doc(activityId)
      .set(
        {
          ...previousData,
          date_last_edited: firebase.firestore.FieldValue.serverTimestamp(),
          added_by: "admin",
        },
        { merge: true }
      );
  }

  async deleteActivity(activityId: string) {
    await this.db.collection(COLLECTION_ID_ACTIVITIES).doc(activityId).delete();
  }

  async fetchActivity(activityId: string): Promise<Activity> {
    const doc = await this.db
      .collection(COLLECTION_ID_ACTIVITIES)
      .doc(activityId)
      .get();
    if (!doc.exists) {
      console.error("Activity not found");
      const response = {} as Activity;
      return response;
    } else {
      const response = { ...doc.data() } as Activity;
      return response;
    }
  }

  async adminFetchAllActivities(): Promise<{ [key: string]: Activity }> {
    // let activities: Array<Activity> = [];
    const activitiesData = await this.db
      .collection(COLLECTION_ID_ACTIVITIES)
      .get();
    const results = activitiesData.docs.reduce(function (acc: any, snapshot) {
      const data = snapshot.data() as any;
      if (data) {
        acc[snapshot.id] = {
          id: snapshot.id,
          name: data.name,
          topic: data.topic,
          tags: data.tags,
          related_activities: data.related_activities,
          "sub-topic": data["sub-topic"],
          address: data.address,
          lat: data.lat,
          long: data.long,
          date_created: data.date_created,
          date_last_edited: data.date_last_edited,
          published: data.published,
        };
      }
      return acc;
    }, {} as Array<Activity>);
    return results;
  }

  // async updateTags() {
  //   this.db.collection("activities").get().then(function(querySnapshot) {
  //     querySnapshot.forEach(function(doc) {
  //         if (doc.data().sub-topic == 'restaurang') {

  //         }
  //         doc.ref.update({
  //             capital: true
  //         });
  //     });
  // });
  // }
  //   async updateActivity(
  //     activityId: string,
  //     newActivityData: Object
  //   ): Promise<void> {
  //     console.log(activityId);
  //     await this.db
  //       .collection(COLLECTION_ID_ACTIVITIES)
  //       .doc(activityId)
  //       .set(newActivityData, { merge: true });
  //   }
}
