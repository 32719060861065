import React, { useEffect, useState } from "react";
import { database } from "../utils/firebase";
import Activity from "../models/activities";
import ActivityForm from "../components/admin/ActivityForm";
import { useAuth } from "../contexts/AuthContext";
import Header from "../components/Header";
import { Link } from "react-router-dom";
import * as ROUTES from "../utils/routes";
import SuccessIcon from "../images/success-icon.png";

interface EditActivityProps {
  match: any;
}

export default function EditActivity({ match }: EditActivityProps) {
  const user = useAuth();
  const [activityToEdit, setActivityToEdit] = useState({} as Activity);
  const [successfullySaved, setSuccessfullySaved] =
    React.useState<boolean>(false);
  const editActivityHandler = async (
    activityId: string,
    newActivity: Activity
  ) => {
    // if (newActivity.tags) {
    //   const tagsAsArray = newActivity.tags.split(",").map((tag) => tag.trim());
    // }
    database
      .updateActivity(activityId, {
        ...newActivity,
      })
      .then(() => setSuccessfullySaved(true))
      .catch((e) => {
        console.error(e);
      });
  };

  React.useEffect(() => {
    const fetchActivity = async () => {
      const data = await database.fetchActivity(match.params.activityId);
      setActivityToEdit({ ...data });
    };
    fetchActivity();
  }, []);

  return (
    <div className="">
      {successfullySaved ? (
        <section>
          <div className="form__container">
            <div className="form__success-message">
              <img
                src={SuccessIcon}
                alt="success"
                height="30"
                style={{ marginRight: 10 }}
              />
              {/* <h2>Success</h2> */}
              <h2>Activity successfully saved.</h2>
            </div>
            <div className="form__link">
              <Link to={ROUTES.ACTIVITIES}>
                <button className="btn">back to activities</button>
              </Link>
            </div>
          </div>
        </section>
      ) : (
        <ActivityForm
          onAddOrEditActivity={editActivityHandler}
          selectedActivityId={match.params.activityId}
          selectedActivity={activityToEdit}
        />
      )}
    </div>
  );
}
