import { http } from "./fetch";
// import HttpResponse from './fetch'

const GOOGLE_MAPS_API_ENDPOINT =
  "https://maps.googleapis.com/maps/api/geocode/";
export const GOOGLE_MAPS_API_KEY = "AIzaSyAUByyktxVVS65t5ABJdhPzoXymvbQkC2g";

interface Coordinates {
  lat: number;
  lng: number;
}

interface GoogleGeoCodingResponse {
  results: {
    geometry: {
      location: {
        lat: number;
        lng: number;
      };
    };
  }[];
  status: "OK" | "ZERO_RESULTS";
}

//   TODO: move this to cloud function
const getAddressCoordinates = async (address: string): Promise<Coordinates> => {
  //   let response: HttpResponse<GoogleMapsResponseBody>
  const urlEncodedAddress = encodeURI(address);
  const request = `${GOOGLE_MAPS_API_ENDPOINT}json?address=${urlEncodedAddress}&key=${GOOGLE_MAPS_API_KEY}`;
  try {
    const response = await http<GoogleGeoCodingResponse>(request);
    // console.log(request);
    if (response.parsedBody?.status !== "OK") {
      throw new Error("Could not fetch location from Google geocoding");
    }
    return response.parsedBody?.results[0].geometry.location;
  } catch (response) {
    console.error(response);
    throw new Error("Could not fetch location from Google geocoding");
  }
};

export default getAddressCoordinates;
