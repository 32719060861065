import React, {useEffect, useState} from 'react'
import { database } from "../utils/firebase";
import Activity from '../models/activities';
import { useAuth } from '../contexts/AuthContext';
import Header from "../components/Header";
import ActivityList from '../components/admin/ActivityList';
import Spinner from "../components/admin/LoadingAnimation";

interface EditActivityProps {
  match: any;
}


export default function EditActivity({match}:EditActivityProps) {
  const [allActivities, setAllActivities] = useState<{ [key: string]: Activity }>({});
  const [isLoading, setIsLoading] = useState<boolean>(false);

  React.useEffect(() => {
    setIsLoading(true)
    database
      .adminFetchAllActivities()
      .then((list) => {
        setAllActivities(list);
       })
      .catch((e) => console.error("Could not fetch activities: ", e))
      .finally(()=>setIsLoading(false))
    }, []);
  return (
    <div className="">
      {isLoading 
      ?<Spinner/>
      :<ActivityList activities={allActivities} />
       }
      
    </div>
  )
}