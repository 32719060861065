import React from "react";
import Button from "@material-ui/core/Button";
import logo from "../images/logo.svg";
import { useAuth } from "../contexts/AuthContext";
import { Link } from "react-router-dom";
import * as ROUTES from "../utils/routes";
import styles from "../styles/footer.module.css";

const Footer: React.FC = () => {
  return (
    <footer className="footer">
      <div className="footer__address">
        <h4>MiniPass AB</h4>
        <p className="footer__paragraph"></p>
        <p className="footer__paragraph">Skeppargatan 54, 11458 Stockholm, Sweden</p>
        <p className="footer__paragraph">Company number: 559284-6520</p>
      </div>
      <div className="footer__copyright">
        <ul>
          <li>
            Copyright &copy; 2021 MiniPass. All rights reserved.
          </li>
        </ul>
        <div className="footer__links">
        <ul>
          <li>
            <Link to={ROUTES.CONTACT}>Contact</Link>
          </li>
          <li>
            <Link to={ROUTES.TERMS}>Terms</Link>
          </li>
          <li>
            <Link to={ROUTES.PRIVACY}>Privacy</Link>
          </li>
        </ul>
      </div>
      </div>
      
    </footer>
  );
};

export default Footer;
