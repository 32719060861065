import LocationOnIcon from "@material-ui/icons/LocationOn";

interface LocationPinProps {
  lat: number;
  lng: number;
}

const LocationPin = (props: LocationPinProps) => (
  <div className="pin">
    <LocationOnIcon style={{ color: "red" }} />
  </div>
);

export default LocationPin;
