export const Topics: Record<string, string> = {
  animals: "animals",
  art: "art",
  beach: "beach",
  castles: "castles",
  cinema: "cinema",
  courses: "courses",
  food: "food",
  indoor_playground: "indoor playground",
  libraries: "libraries",
  museums: "museums",
  on_stage: "on stage",
  open_kindergarden: "open kindergarden",
  playground: "playground",
  swimming_pool: "swimming pool",
};

export const CoursesSubTopics: Record<string, string> = {
  baby_music: "baby music",
  baby_swim: "baby swim",
  baby_cpr: "baby CPR",
  family_photography: "family photography",
};

export const FoodSubTopics: Record<string, string> = {
  brunch: "brunch",
  cafe: "cafe",
  restaurant: "restaurant",
};
