import React from "react";
import GoogleMapReact from "google-map-react";
import LocationPin from "./LocationPin";

import { GOOGLE_MAPS_API_KEY } from '../../utils/googleMaps';

const STOCKHOLM = { lat: 58.0, lng: 17.0 };

interface GoogleMapProps {
  coordinates: { lat: number; lng: number };
}

const GoogleMap = (props: GoogleMapProps) => {
  const defaultLocation = STOCKHOLM;
  return (
    <GoogleMapReact
      bootstrapURLKeys={{ key: GOOGLE_MAPS_API_KEY }}
      defaultCenter={defaultLocation}
      center={props.coordinates}
      defaultZoom={17}
    >
      <LocationPin
        lat={props.coordinates.lat}
        lng={props.coordinates.lng}
        // text={location.address}
      />
    </GoogleMapReact>
  );
};

export default GoogleMap;
