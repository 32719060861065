import React, { useEffect, useState } from "react";
import {
  withStyles,
  Theme,
  createStyles,
  makeStyles,
} from "@material-ui/core/styles";
import IconButton from "@material-ui/core/IconButton";
import CancelOutlinedIcon from "@material-ui/icons/CancelOutlined";
import CheckCircleIcon from "@material-ui/icons/CheckCircle";
import CheckCircleIconOutlined from "@material-ui/icons/CheckCircleOutlined";
import Paper from "@material-ui/core/Paper";
import VisibilityIcon from "@material-ui/icons/Visibility";
import CheckIcon from "@material-ui/icons/Check";
import Activity from "../../models/activities";
import DeleteIcon from "@material-ui/icons/Delete";
import EditIcon from "@material-ui/icons/Edit";
import Table from "@material-ui/core/Table";
import TableBody from "@material-ui/core/TableBody";
import TableCell from "@material-ui/core/TableCell";
import TableHead from "@material-ui/core/TableHead";
import TableRow from "@material-ui/core/TableRow";
import moment from "moment";
import { firestore } from "firebase-admin";
import {
  BrowserRouter as Router,
  Route,
  Switch,
  Redirect,
  useHistory,
} from "react-router-dom";
import { database } from "../../utils/firebase";
import * as ROUTES from "../../utils/routes";
import { Topics } from "../../models/topics";
import Button from "@material-ui/core/Button";
import InputLabel from "@mui/material/InputLabel";
import MenuItem from "@mui/material/MenuItem";
import FormControl from "@mui/material/FormControl";
import Select, { SelectChangeEvent } from "@mui/material/Select";

const StyledTableCell = withStyles((theme: Theme) =>
  createStyles({
    head: {
      // backgroundColor: theme.palette.primary.main,
      // color: theme.palette.common.white,
      fontWeight: "bold",
    },
    body: {
      fontSize: 14,
    },
  })
)(TableCell);

const useStyles = makeStyles({
  table: {
    minWidth: 650,
  },
  margin: {
    margin: 0,
  },
});

const getDate = (date: firestore.Timestamp | null | undefined) => {
  if (!date || date === undefined) return "--";
  // return JSON.stringify(date);
  // console.log(JSON.stringify(date?.toDate()));
  return moment(date?.toDate()).format("L");
};

interface ActivityListProps {
  activities: { [key: string]: Activity };
}

export default function ActivityList({ activities }: ActivityListProps) {
  const classes = useStyles();
  const history = useHistory();
  const [userSelectedCategory, setUserSelectedTopic] = useState<string>("all");
  const [filteredActivities, setFilteredActivities] = useState<{
    [key: string]: Activity;
  }>({});

  function removeKey(obj: any, deleteKey: string) {
    let clone = Object.assign({}, obj);
    delete clone[deleteKey];
    return clone;
  }

  const updateTags = async () => {
    await updateTags();
  };

  const onDeleteActivity = async (activityId: string) => {
    var result = window.confirm(
      "Are you sure you want to delete this activity?"
    );
    // TODO: this doesn't work, the deleted activiity ist not removed
    if (result) {
      await database.deleteActivity(activityId);
      const newFilteredActivitied = removeKey(
        filteredActivities[activityId],
        activityId
      );
      setFilteredActivities(newFilteredActivitied);
    }
    return;
  };

  const onClickActivity = async (activityId: string) => {
    history.push(`${ROUTES.EDIT_ACTIIVTY}/${activityId}`);
  };

  useEffect(() => {
    var filtered = Object.keys(activities).reduce(function (
      filtered: any,
      key
    ) {
      if (activities[key].topic === userSelectedCategory)
        filtered[key] = activities[key];
      return filtered;
    },
    {});
    if (userSelectedCategory === "all") {
      setFilteredActivities(activities);
    } else {
      setFilteredActivities(filtered);
    }
  }, [, userSelectedCategory]);

  const handleCategory = (selectedCategory: string): void => {
    setUserSelectedTopic(selectedCategory);
  };
  const filterTopic = (event: any): void => {
    setUserSelectedTopic(event.target.value);
  };

  // React.useEffect(() => {
  //   console.log(activities);
  // }, [, userSelectedCategory]);Fslpi

  return (
    <section>
      <div className="admin__container">
        <div
          style={{
            display: "flex",
            alignItems: "center",
            width: "100%",
            justifyContent: "space-between",
            margin: "20px 0 20px 0",
          }}
        >
          <h1>{Object.keys(filteredActivities).length} Activities</h1>
          <div style={{ display: "flex", alignItems: "center" }}>
            <FormControl sx={{ m: 1, minWidth: 120 }} size="small">
              {/* <InputLabel id="topic-selector-label">Topic</InputLabel> */}
              <Select
                // labelId="topic-selector-label"
                variant="outlined"
                // style={{ minWidth: "100px" }}
                id="topic-selector"
                onChange={filterTopic}
                // label="Topic"
                value={userSelectedCategory}
              >
                <MenuItem value="all">Filter by topic</MenuItem>
                {Object.keys(Topics).map((topic: string) => (
                  <MenuItem key={topic} value={topic}>
                    {Topics[topic]}
                  </MenuItem>
                ))}
              </Select>
            </FormControl>
            <div style={{ width: "150px" }}>
              <Button
                href={ROUTES.NEW_ACTIVITY}
                size="small"
                className="btn btn-primary btn--new-activity"
              >
                New Activity
              </Button>
            </div>
          </div>
        </div>
        {/* <div className="topicsFilter">
          <div>
            {Object.keys(Topics)
              .slice(0, 7)
              .map((topic) => (
                <span
                  key={topic}
                  id={`${topic}Badge`}
                  className={
                    topic === userSelectedCategory
                      ? "topic__badge topic__badge--selected"
                      : "topic__badge"
                  }
                  onClick={() => handleCategory(topic)}
                >
                  {`${Topics[topic]}`}
                </span>
              ))}
          </div>
          <div>
            {Object.keys(Topics)
              .slice(7, 13)
              .map((topic) => (
                <span
                  key={topic}
                  id={`${topic}Badge`}
                  className={
                    topic === userSelectedCategory
                      ? "topic__badge topic__badge--selected"
                      : "topic__badge"
                  }
                  onClick={() => handleCategory(topic)}
                >
                  {`${Topics[topic]}`}
                </span>
              ))}
          </div>
        </div> */}
        <Paper elevation={0}>
          <Table className={classes.table} aria-label="simple table">
            <TableHead>
              <TableRow>
                {/* <StyledTableCell>Topic</StyledTableCell> */}
                <StyledTableCell>Id</StyledTableCell>
                <StyledTableCell>Name</StyledTableCell>
                <StyledTableCell>Topic</StyledTableCell>
                {/* <StyledTableCell>Sub-topics</StyledTableCell> */}
                <StyledTableCell>Tags</StyledTableCell>
                <StyledTableCell>Related activities</StyledTableCell>
                {/* <StyledTableCell>Date added</StyledTableCell> */}
                <StyledTableCell>Last edited</StyledTableCell>
                <StyledTableCell>Published</StyledTableCell>
                <StyledTableCell></StyledTableCell>
                <StyledTableCell></StyledTableCell>
              </TableRow>
            </TableHead>
            <TableBody>
              {filteredActivities && Object.keys(filteredActivities) ? (
                Object.keys(filteredActivities).map((key: string) => (
                  <TableRow style={{ color: "black" }} key={key}>
                    <StyledTableCell>{key}</StyledTableCell>
                    <StyledTableCell>
                      {filteredActivities[key].name}
                    </StyledTableCell>
                    <StyledTableCell>
                      {filteredActivities[key]["topic"]}
                    </StyledTableCell>
                    {/* <StyledTableCell>
                      {filteredActivities[key]["sub-topic"]}
                    </StyledTableCell> */}
                    <StyledTableCell>
                      {typeof filteredActivities[key].tags === "object"
                        ? filteredActivities[key].tags?.join(", ")
                        : typeof filteredActivities[key].tags === "undefined"
                        ? ""
                        : "invalid format"}
                    </StyledTableCell>
                    <StyledTableCell>
                      {typeof filteredActivities[key].related_activities ===
                      "object"
                        ? filteredActivities[key].related_activities?.join(", ")
                        : typeof filteredActivities[key].related_activities ===
                          "undefined"
                        ? ""
                        : "invalid format"}
                    </StyledTableCell>
                    {/* <StyledTableCell>
                      {getDate(filteredActivities[key].date_created)}
                    </StyledTableCell> */}
                    <StyledTableCell>
                      {getDate(filteredActivities[key].date_last_edited)}
                    </StyledTableCell>
                    <StyledTableCell>
                      {typeof filteredActivities[key].published ==
                        "undefined" || filteredActivities[key].published ? (
                        <CheckCircleIcon style={{ color: "#3cbc3c" }} />
                      ) : (
                        <CancelOutlinedIcon style={{ color: "#b5b2b2" }} />
                      )}
                    </StyledTableCell>
                    <StyledTableCell>
                      <IconButton
                        aria-label="edit"
                        className={classes.margin}
                        onClick={onClickActivity.bind(null, key)}
                      >
                        <EditIcon fontSize="small" />
                      </IconButton>
                    </StyledTableCell>
                    <StyledTableCell>
                      <IconButton
                        aria-label="delete"
                        className={classes.margin}
                        onClick={onDeleteActivity.bind(null, key)}
                      >
                        <DeleteIcon fontSize="small" />
                      </IconButton>
                    </StyledTableCell>
                  </TableRow>
                ))
              ) : (
                <TableRow style={{ color: "black" }}>
                  <StyledTableCell>No results</StyledTableCell>
                </TableRow>
              )}
            </TableBody>
          </Table>
        </Paper>
      </div>
    </section>
  );
}
