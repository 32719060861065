import React, {useState, useEffect, useContext} from 'react';
import {firebaseAuth} from '../utils/firebase';
import User, {UserData} from '../models/user';


interface ValueProvider {
    currentUser: User | null,
    login: Function,
    logout: Function,
}

const AuthContext = React.createContext<ValueProvider>({
    currentUser: null,
    login: () => {},
    logout: () => {},
});

const useAuth = () => useContext(AuthContext);


function AuthProvider(props:any) {
    const localStorageKey = 'user-token';

    const [loading, setLoading] = useState(true);
    const [currentUser, setCurrentUser] = useState<any | null>(null);

    // check if local storage token and login if there

    function login(email:string, password:string):Promise<any> {
        console.log('Logging in!');
        return firebaseAuth.signInWithEmailAndPassword(email,password);
    }

    const logout = async (): Promise<any> =>  {
        // window.localStorage.removeItem(localStorageKey)
        return firebaseAuth.signOut();
    }

    useEffect(() => {
        const unsubscribe = firebaseAuth.onAuthStateChanged((user) => {
            setCurrentUser(user);
            setLoading(false);
        });
        return unsubscribe;
    }, [])

    // const value:ValueProvider = 

    return <AuthContext.Provider value={{
        currentUser,
        login, 
        logout
    }} {...props} />
}

export {AuthProvider, useAuth}