import React, { useState } from "react";
import "./App.css";
import Activities from "./pages/activities";
import Home from "./pages/home";
import About from "./pages/about";
import Contact from "./pages/contact";
import Terms from "./pages/terms";
import Privacy from "./pages/privacy";
import { useAuth } from "./contexts/AuthContext";
import * as ROUTES from "./utils/routes";
import Login from "./components/Login";
import {
  BrowserRouter as Router,
  Route,
  Switch,
  Redirect,
  useHistory,
} from "react-router-dom";
import { useLocation } from "react-router";
import EditActivity from "./pages/editActivity";
import NewActivity from "./pages/newActivity";
import Header from "./components/Header";
import Footer from "./components/Footer";
import CookieConsentBanner from "./components/CookieConsent";

function App() {
  const { currentUser } = useAuth();
  if (currentUser?.email) {
    return <AdminApp />;
  } else {
    return <MainSite />;
  }
}

function AdminApp() {
  return (
    <Router>
      <Header />
      <Switch>
        <Route exact path={ROUTES.HOME} component={Home} />
        <Route exact path={ROUTES.ABOUT} component={About} />
        <Route exact path={ROUTES.CONTACT} component={Contact} />
        <Route exact path={ROUTES.TERMS} component={Terms} />
        <Route exact path={ROUTES.PRIVACY} component={Privacy} />
        <Route exact path={ROUTES.ACTIVITIES} component={Activities} />
        <Route exact path={ROUTES.NEW_ACTIVITY} component={NewActivity} />
        <Route
          path={`${ROUTES.EDIT_ACTIIVTY}/:activityId`}
          component={EditActivity}
        />
        <Redirect to={ROUTES.HOME} />
      </Switch>
      <Footer />
    </Router>
  );
}

function MainSite() {
  return (
    <Router>
      <Header />
      <Switch>
        <Route exact path={ROUTES.HOME} component={Home} />
        <Route exact path={ROUTES.ABOUT} component={About} />
        <Route exact path={ROUTES.CONTACT} component={Contact} />
        <Route exact path={ROUTES.TERMS} component={Terms} />
        <Route exact path={ROUTES.PRIVACY} component={Privacy} />
        <Route exact path={ROUTES.SIGN_IN} component={Login} />
        <Route exact path={ROUTES.NEW_ACTIVITY} component={NewActivity} />

        <Redirect to={ROUTES.HOME} />
      </Switch>
      <CookieConsentBanner />
      <Footer />
    </Router>
  );
}

export default App;
