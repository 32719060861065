import React from "react";
import CookieConsent from "react-cookie-consent";

const CookieConsentBanner: React.FC = () => {
  return (
    <CookieConsent
      location="bottom"
      buttonText="OK"
      cookieName="cookieConsent"
      style={{ background: "#2B373B" }}
      buttonStyle={{ backgroundColor:"rgb(145,82,255)", color: "white", fontSize: "13px" }}
      expires={150}
      onAccept={() => {
       
      }}
      enableDeclineButton
      declineButtonText="Decline"
      declineButtonStyle={{backgroundColor:"#e4e4e4", color:"#333"}}
      onDecline={() => {
        let disableCookiesByDefaut = (window as { [key: string]: any })[`ga-disable-G-Q93ZKXD4J2`] as boolean;
        disableCookiesByDefaut = true; 
        document.cookie = "ga-disable-G-Q93ZKXD4J2=true; expires=Thu, 31 Dec 2099 23:59:59 UTC;path=/";
      }}
    >
      We would like to put some small files called cookies on your device to make the site work and for analytics about how our site is used in order to improve it.{" "}
      {/* <span style={{ fontSize: "10px" }}>This bit of text is smaller :O</span> */}
    </CookieConsent>
  );
};

export default CookieConsentBanner;