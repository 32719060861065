interface HttpResponse<T> extends Response {
  parsedBody?: T;
}

export async function http<T>(request: RequestInfo): Promise<HttpResponse<T>> {
  const response: HttpResponse<T> = await fetch(request);
  try {
    response.parsedBody = await response.json();
  } catch (e) {
    console.error("Error making the http fetch: " + e);
    throw new Error(response.statusText);
  }
  if (!response.ok) throw new Error(response.statusText);

  return response;
}
