import React from "react";
import HomePreview from "../images/app/home-transparent.png";
import AppstoreLogo from "../images/appstore.svg";

export default function Home() {
  return (
    <section className="hero">
      <div className="container">
      <div className="hero__image">
        <img
          
          src={HomePreview}
        />
      </div>
      <div className="hero__text">
        <h1>
          Find family-friendly activities in and around Stockholm
        </h1>
        <p>
          Your ultimate guide to find fun family-friendly activities in and
          around Stockholm. Explore activities by topic, near you or on a map.
        </p>
        <div className="hero__appstore-link">
          <h3>Download from the App Store </h3>
          <a
            href="https://apps.apple.com/se/app/minipass/id1569881903"
            target="_blank"
          >
            <img className="appstore-btn" src={AppstoreLogo} alt="App Store link" height="50" />
          </a>
        </div>
      </div>
      
     
      
      </div>
    </section>
  );
}
