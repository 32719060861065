import React, { useState } from "react";
import { database } from "../utils/firebase";
import Activity from "../models/activities";
import ActivityForm from "../components/admin/ActivityForm";
import Header from "../components/Header";
import { Link } from "react-router-dom";
import * as ROUTES from "../utils/routes";
import SuccessIcon from "../images/success-icon.png";

export default function NewActivity() {
  const [activityToEdit, setActivityToEdit] = useState({} as Activity);
  const [successfullySaved, setSuccessfullySaved] = React.useState<boolean>(
    false
  );
  const addActivityHandler = async (
    activityId: string,
    newActivity: Activity
  ) => {
    // setActivities((prevActivities) => [...prevActivities, newActivity]);
    database
      .addNewActivity(activityId, newActivity)
      .then(() => setSuccessfullySaved(true))
      .catch((e) => {
        console.error(e);
      });
  };
  return (
    <div className="">
      {successfullySaved ? (
        <section>
          <div className="form__container">
            <div className="form__success-message">
              <img
                src={SuccessIcon}
                alt="success"
                height="30"
                style={{ marginRight: 10 }}
              />
              {/* <h2>Success</h2> */}
              <h2>Activity successfully created.</h2>
            </div>
            <div className="form__link">
              <Link to={ROUTES.ACTIVITIES}>
                <button className="btn">back to activities</button>
              </Link>
            </div>
          </div>
        </section>
      ) : (
        <ActivityForm
          onAddOrEditActivity={addActivityHandler}
          selectedActivityId={null}
          selectedActivity={null}
        />
      )}
    </div>
  );
}
