import React from "react";
import ExplorePreview from "../images/app/explore-food.png";
import MapPreview from "../images/app/map-explore.png";

export default function About() {
  return (
    <div className="container">
      <div
        style={{
          marginTop: "4em",
          display: "flex",
          alignItems: "center",
          justifyContent: "space-evenly",
          height: "400",
          width: "100%",
        }}
      >
        <div style={{ maxWidth: "40%" }}>
          <h1 style={{ fontSize: "3.2rem", lineHeight: "1.3em" }}>
            About us
          </h1>

          <p
            style={{
              marginTop: 0,
              lineHeight: "1.6em",
              color: "#7c7878",
              fontSize: "1.1rem",
            }}
          >
            We want to help parents with new borns and toddlers continue to do
            the things they love, without having to worry about having a baby
            and pram.
          </p>
        </div>
        <img
          style={{
            height: "400",
            maxWidth: "auto",
            transform: "rotate(0deg)",
          }}
          src={ExplorePreview}
        />
      </div>
      {/* <div
        style={{
          marginTop: "4em",
          display: "flex",
          alignItems: "center",
          justifyContent: "space-evenly",
          height: "400",
          width: "100%",
        }}
      >
         <img
          style={{
            height: "400",
            maxWidth: "auto",
            transform: "rotate(0deg)",
          }}
          src={MapPreview}
        />
        <div style={{ maxWidth: "40%" }}>
          <h1 style={{ fontSize: "3.2rem", lineHeight: "1.3em" }}>
            Who we are
          </h1>
          <p
            style={{
              marginTop: 0,
              lineHeight: "1.6em",
              color: "#7c7878",
              fontSize: "1.1rem",
            }}
          >
            We are two mothers, based in Stockholm, Sweden. Our company WeLind
            AB is aimed at making apps for parents like us.
          </p>
        </div>
       
      </div> */}

    </div>
  );
}
