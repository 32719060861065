import React from "react";
import Activity from "../../models/activities";

import { makeStyles, Theme } from "@material-ui/core/styles";
import { Box, Button } from "@material-ui/core";
import AccountCircleOutlinedIcon from "@material-ui/icons/AccountCircleOutlined";
import {
  uploadActivityImageAndUpdateActivityDataReturnURL,
  storage,
} from "../../utils/firebase";
import Spinner from "./LoadingAnimation";
// import imagePlaceholder from "../images/image-placeholder-icon-5.jpg";
import imagePlaceholder from "../../images/placeholder2.png";

const IMAGE_LIMIT_4_MB = 4 * 1024 * 1024; // Also enfored in storage rules
const IMAGE_HEIGHT = 273;
const IMAGE_WIDTH = 414;
const PHONE_HEIGHT = 848;
const PHONE_WIDTH = 414;

interface ProfilePictureWithUploadProps {
  updateImageUrl: (url: string) => void;
  activityId: string;
  activity: Activity;
}

const useStyles = makeStyles((theme: Theme) => ({
  phoneOutLine: {
    height: PHONE_HEIGHT,
    width: PHONE_WIDTH,
    overflow: "hidden",
    border: "19px solid rgba(0,0,0)",
    borderRadius: "60px",
    margin: "10px auto",
    
  },
  photoContainer: {
    position: "relative",
    padding: 0,
    display: "flex",
    alignItems: "center",
    height: '100%',
    width: '100%',
    overflow: "hidden",
    flexDirection:'column',
  },
  photo: {
    width: '100%',
    height: IMAGE_HEIGHT,
    objectFit: 'cover',
    marginTop: 80  
  },
}));

interface ActivityImageProps {
  showSpinner: boolean;
  imageUrl?: string;
  className: string;
}

const ActivityImage = ({
  showSpinner,
  imageUrl,
  className,
}: ActivityImageProps): JSX.Element => {
  if (showSpinner) {
    return (
      <Box
        className={className}
        height={IMAGE_HEIGHT}
        style={{ display: "flex", alignItems: "center" }}
      >
        <Spinner />
      </Box>
    );
  }

  if (imageUrl) {
    return <img src={imageUrl} className={className} />;
  }

  return <img src={imagePlaceholder} />;
};

const PictureWithUpload = ({
  updateImageUrl,
  activityId,
  activity,
}: ProfilePictureWithUploadProps): JSX.Element => {
  const classes = useStyles();
  let initialImageURL: string | undefined = undefined;
  if (activity.image_url && activity.image_url !== '') {
    initialImageURL = activity.image_url;
  } 
  const [imageURL, setImageURL] = React.useState<string | undefined>(
    initialImageURL
  );
  const [isLoading, setLoading] = React.useState<boolean>(false);

  return (
    <div className={classes.phoneOutLine}>
      <div className={classes.photoContainer}>
        <ActivityImage
          showSpinner={isLoading}
          className={classes.photo}
          imageUrl={initialImageURL}
        />

        <input
          accept="image/jpeg,image/png"
          id="upload-profile-image"
          type="file"
          style={{ display: "none" }}
          onChange={async function (event): Promise<void> {
            if (event.target && event.target.files && event.target.files[0]) {
              const selectedFile: File = event.target.files[0];
              if (selectedFile.size > IMAGE_LIMIT_4_MB) {
                alert("File must be less than 4MB");
                return;
              }

              setLoading(true);
              const uploadedImageURL = await uploadActivityImageAndUpdateActivityDataReturnURL(
                activityId,
                selectedFile
              );
              console.log(uploadedImageURL);
              // setTimeout(() => setLoading(false), 3000);
              setLoading(false);
              setImageURL(uploadedImageURL);
              updateImageUrl(uploadedImageURL);
            }
          }}
        />

        {!isLoading && (
          <Button
            component="label"
            htmlFor="upload-profile-image"
            style={{
              position: "absolute",
              left: "50%",
              bottom: "80px",
              marginLeft: "3px",
              transform: "translate(-50%,-50%)",
              color: "rgba(78,78,78,0.7)",
            }}
            className="button button-default"
            disableElevation
            variant={activity.image_url ? "contained" : "text"}
          >
            {activity.image_url ? "Change image" : "Add image"}
          </Button>
        )}
      </div>
    </div>
  );
};

export { PictureWithUpload };
