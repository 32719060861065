import React from "react";
import ExplorePreview from "../images/app/explore-food.png";
import MapPreview from "../images/app/map-explore.png";

export default function Terms() {
  return (
    <div className="container">
      <div
        style={{
          marginTop: "4em",
          display: "flex",
          alignItems: "center",
          justifyContent: "space-evenly",
          height: "400",
          width: "100%",
        }}
      >
        <div style={{ maxWidth: "40%" }}>
          <h1 style={{ fontSize: "3.2rem", lineHeight: "1.3em" }}>
            Terms
          </h1>
          <p>Coming soon</p>
        </div>
      </div>
    </div>
  );
}
