import firebase from "firebase/app";
import { Database } from "./database";
import { Storage } from "./storage";
import config from "./config";
// const firebaseAuth = firebase.auth()
// const db = firebase.firestore()
import "firebase/auth";
import "firebase/firestore";
import "firebase/analytics";
import Activity from "../../models/activities";

const firebaseApp = firebase.initializeApp(config);
const firebaseAuth = firebaseApp.auth()
const firebaseStorage = firebaseApp.storage();
const firestore = firebase.firestore();
const database = new Database(firestore, firebaseAuth);
const storage = new Storage(firebaseStorage, firebaseAuth);
// const firebaseAnalytics = firebase.analytics();

const handleFileInputSelectedGetImageDataUrl = (file: File) => {
  return new Promise<string>((resolve) => {
    // Based on:
    //  https://stackoverflow.com/questions/4459379/preview-an-image-before-it-is-uploaded
    const fileReader = new FileReader();

    fileReader.onload = function (e) {
      if (!e.target || !e.target.result || typeof e.target.result != "string") {
        return;
      }
      const imageAsB64 = e.target.result;
      resolve(imageAsB64);
    };

    const filePath: Blob = new Blob([file], { type: "image/jpeg" }); // or png

    fileReader.readAsDataURL(filePath);
  });
};

async function uploadActivityImageAndUpdateActivityDataReturnURL(
  activityId: string,
  selectedFile: File
): Promise<string> {
  const storageRef = await storage.uploadImageGetRef(activityId, selectedFile);

  // In Storage there is a resizing extension which suffixes resized files.
  // A follow up load will likely have had the file replaced with the resized version
  //   const resizedImageRef = storage.getResizedUserImageStorageFullPath(
  //     selectedFile
  //   );

  // await database.addNewActivity(activityId, {
  //   // photoRefPath: resizedImageRef.fullPath,
  //   photoRefPath: selectedFile,
  // });

  return await storage.getDownURLFromReferencePath(storageRef.fullPath);
}

export {
  firebaseAuth,
  database,
  storage,
  uploadActivityImageAndUpdateActivityDataReturnURL,
};
