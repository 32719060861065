import React from "react";
import Footer from "../components/Footer";
import Header from "../components/Header";
import ExplorePreview from "../images/app/explore-food.png";
import MapPreview from "../images/app/map-explore.png";
import styles from "../styles/contact.module.css";

export default function Contact() {
  return (
    <div className="container">
        <div className={styles.contactUs}>
          <h2 className={styles.contactUs__header}>
            Get in touch
          </h2>
          
          <p className={styles.contactUs__message}>If you have any questions or would like more information please send us an email at:</p>
          <a className={styles.contactUs__emailAddress} href="mailto:info@minipass.se">info@minipass.se</a>
        </div>
       
      </div>
        );
}
